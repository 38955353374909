<template>
  <a-dims-search-bar hide-search>
    <template #search-button>
      <button @click="update" class="focus:outline-none">
        <transition name="fade" mode="out-in">
          <a-loader class="h-6 w-6" color="text-blue-500" v-if="loading" />
          <i ref="checkIcon" v-else class="icon-checkmark text-primary font-bold text-xl p-4 inline-block -mx-4 select-none" />
        </transition>
      </button>
    </template>

    <div class="flex justify-between p-4 space-x-2 -mx-4">
      <button @click="$router.back" class="space-x-2 text-gray-700 uppercase flex items-center">
        <i class="icon-arrowLeft text-gray-600" />
        <span class="font-bold">Atrás</span>
      </button>
    </div>
  </a-dims-search-bar>

  <div v-if="user.isRestaurant" class="pt-14">
    Link Restaurant
  </div>
  <div v-else class="pt-14">
    links foodie
  </div>
</template>

<script>
import { useSession } from '../../hooks'

export default {
  name: 'training',
  setup () {
    const { user } = useSession()

    return {
      user
    }
  }
}
</script>

<style scoped>

</style>
